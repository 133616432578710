import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page from './components/page';
import Dashboard from './components/dashboard';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div className="App">
      <ToastContainer position="top-right" theme="colored" limit={1} />
      <Router>
        <Routes>
          <Route path="/:projectName" element={<Dashboard />} />
          <Route path="/:projectName/:pageName" element={<Page />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;