import axios from 'axios';
import config from '../config';

const axiosApi = axios.create({
  baseURL: `${config.api.BASE_URL}${config.api.API_PREFIX}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosApi;
