import axiosApi from './axiosApi';


const getFigmaContent = async (data: {}) => {
  return axiosApi.post('figma/content', { ...data });
};

const getProject = async (data: {}) => {
  return axiosApi.post('figma/project', { ...data });
};

const updateFigmaContent = async (data: {}) => {
  return axiosApi.post('figma/update', { ...data });
};

const figmaApi = {
    getFigmaContent,
    getProject,
    updateFigmaContent
  };
  
  export default figmaApi;