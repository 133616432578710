import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from './modal';
import figmaApi from '../api/figmaApi';
import { TextNode, Data, FigmaContentData, UpdateFigmaContentData } from '../interfaces';

function Page() {
    const { pageName } = useParams<{ pageName: string }>();
    const hasFetchedData = useRef(false);

    const [data, setData] = useState<Data>({ page_name: '', page_id: '', page_width: 0, page_height: 0, image: '', text_nodes: [] });
    const [selectedText, setSelectedText] = useState<string>('');
    const [selectedTextId, setSelectedTextId] = useState<string>('');
    const [characterWarningLength, setCharacterWarningLength] = useState<number>(0);
    const [characterBlockLength, setCharacterBlockLength] = useState<number>(0);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [updatedTextNodes, setUpdatedTextNodes] = useState<Array<TextNode>>([]);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isImageLoad, setIsImageLoad] = useState<boolean>(false);


    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const onStringChange = (value: string) => {
        setSelectedText(value)
    }

    const handleTextClick = (node: TextNode) => {
        setSelectedText(node.characters);
        setSelectedTextId(node.id);
        setCharacterWarningLength(node.characterWarningLength);
        setCharacterBlockLength(node.characterBlockLength);
        toggleModal();
    }

    const getFigmaContent = async (data: FigmaContentData) => {
        try {
            const response = await figmaApi.getFigmaContent(data);
            setData(response.data.data);
            setImageUrl(`${response.data.data.image}?${new Date().getTime()}`);
        } catch (error) {
            console.log('Error');
        }
    };

    const updateFigmaContent = async (data: UpdateFigmaContentData) => {
        try {
            const response = await figmaApi.updateFigmaContent(data);
            if (response.status !== 200) {
                toast(response.data.message, { type: 'error' });
            }
        } catch (error) {
            console.log('Error');
        }
    };

    const handleSave = () => {
        let editedNode: any = {};
        const updatedTextNodes = data && data.text_nodes.map(node => {
            if (node.id === selectedTextId && node.characters !== selectedText) {
                editedNode = { ...node, characters: selectedText };
                setUpdatedTextNodes(prevNodes => [...prevNodes, editedNode]);
                return { ...node, characters: selectedText };
            }
            return node;
        });
        setData({ ...data, text_nodes: updatedTextNodes });

        const requestData = {
            page_id: data.page_id,
            text_nodes: [editedNode]
        }
        updateFigmaContent(requestData);
        toggleModal();
    }

    useEffect(() => {
        if (!pageName || hasFetchedData.current) return;
        hasFetchedData.current = true;

        setIsImageLoad(false);
        getFigmaContent({ parent_name: 'helios-website', page_name: pageName })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName]);

    return (
        <div className="App">
            {!isImageLoad &&
                <div className="loader-overlay">
                    <div className="spinner-border text-primary" role="status" />
                </div>
            }
            {data &&
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={`0 0 ${data.page_width} ${data.page_height} `} width={data.page_width} height={data.page_height} style={{ width: '100%', height: '100%' }}
                    >
                        <image
                            key={imageUrl}
                            href={imageUrl}
                            width="100%"
                            height="100%"
                            onLoad={() => setIsImageLoad(true)}
                        ></image>

                        {data.text_nodes.map((node) => {
                            const updatedNode = updatedTextNodes.find(n => n.id === node.id);

                            return (
                                <React.Fragment key={node.id}>
                                    <rect
                                        key={node.id}
                                        id={node.id}
                                        data-text={node.id}
                                        x={node.x}
                                        y={node.y}
                                        width={node.width}
                                        height={node.height}
                                        style={{
                                            fill: (node.is_updated || updatedNode) ? 'white' : 'transparent',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleTextClick(node)}
                                    >
                                        <title>{node.characters}</title>
                                    </rect>
                                    {(isImageLoad && (node.is_updated || updatedNode)) && (
                                        <foreignObject
                                            x={node.x}
                                            y={node.y}
                                            width={node.width}
                                            height={node.height}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: node.fontFamily,
                                                    fontStyle: node.fontStyle,
                                                    fontSize: node.fontSize,
                                                    fontWeight: node.fontWeight,
                                                    letterSpacing: node.letterSpacing,
                                                    lineHeight: `${node.lineHeight}%`,
                                                    whiteSpace: 'pre-wrap',
                                                    alignItems: 'center',
                                                    overflow: 'hidden',
                                                    color: 'black',
                                                    display: 'flex',
                                                    width: '100%',
                                                    height: '100%',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleTextClick(node)}
                                            >
                                                {node.characters}
                                            </div>
                                        </foreignObject>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </svg>
                </div>
            }
            <Modal
                open={showModal}
                onClose={toggleModal}
                text={selectedText}
                characterWarningLength={characterWarningLength}
                characterBlockLength={characterBlockLength}
                onStringChange={onStringChange}
                handleSave={handleSave}
            />
        </div >
    );
}

export default Page;