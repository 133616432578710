import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import figmaApi from '../api/figmaApi';
import { ProjectData } from '../interfaces';

function Dashboard() {
    const { projectName } = useParams<{ projectName: string }>();
    const hasFetchedData = useRef(false);

    const navigate = useNavigate();
    const [data, setData] = useState<Array<any>>([]);

    const getProjects = async (data: ProjectData) => {
        try {
            const response = await figmaApi.getProject(data);
            setData(response.data.data);
        } catch (error) {
            console.log('Error');
        }
    };

    const convertFormat = (text: string) => {
        return text
            .replace(/-/g, ' ')
            .replace(/\b\w/g, match => match.toUpperCase());
    }

    useEffect(() => {
        if (!projectName || hasFetchedData.current) return;
        hasFetchedData.current = true;
        getProjects({ parent_name: projectName });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectName]);

    const handleButtonClick = (projectName: string, pageName: string) => {
        navigate(`/${projectName}/${pageName}`);
    };

    return (
        <>
            <div className="centered-container">
                <div className="text-center">
                    <h1>Figma Press | Eight25</h1>
                    {
                        data.length &&
                        <div className="card" style={{ width: '38rem' }}>
                            <div className="card-body">
                                <h2 className="card-title">{data[0].original_parent_name}</h2>
                                <ul className="list-group list-group-flush">
                                    {data.map(item => (
                                        <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                                            {convertFormat(item.page_name)}
                                            <button className="btn btn-primary" onClick={() => handleButtonClick(item.parent_name, item.page_name)}>View</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Dashboard;
