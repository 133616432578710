import React from 'react';
import { ModalProps } from '../interfaces';

export default function Modal(props: ModalProps) {
  const isWarning = props.text.length >= props.characterWarningLength;
  const isError = props.text.length === props.characterBlockLength;

  return (
    <div
      className={`${"modal"} ${props.open ? "display-block" : "display-none"}`}
    >
      <div className="modal-main">
        <div className="modal-head">
          <h2>Text Update</h2>
        </div>
        <div className="modal-body">
          <textarea
            value={props.text}
            onChange={(e) => props.onStringChange(e.target.value)}
            className="text-field"
            maxLength={props.characterBlockLength}
          />
        </div>
        {!isError && isWarning && (
          <div className="modal-error">
            <span className='warning'>Warning: This character limit might break the desing!</span>
          </div>
        )}
        {isError && <div className="modal-error">
          <span className='error'>Error: Character limit exceeded!</span>
        </div>
        }
        <div className="btn-container">
          <button type="button" className="btn btn-danger" onClick={props.onClose}>
            Close
          </button>
          <button type="button" className="btn btn-primary" disabled={isError} onClick={props.handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}